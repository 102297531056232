<template>
    <div style="display: inline-flex">
        <date-range-picker v-model="form" :disabled="loading" clearable start-placeholder="上线开始时间"
                         end-placeholder="上线结束时间" style="width:350px;margin-right: 3px"></date-range-picker>

        <el-select v-model="form.module" :disabled="loading" placeholder="模块" filterable
                   clearable style="width: 150px; margin-right: 3px" @change="init">
          <el-option v-for="(v, k) in image_module_map" :key="k" :label="v" :value="k"></el-option>
        </el-select>

        <el-select v-model="form.country" :disabled="loading" placeholder="全部国家" filterable clearable
                   style="margin-right: 3px">
          <el-option v-for="c in $root.country_list" :key="c.ip_name" :value="c.ip_name"
                     :label="c.en_name + ' (' + c.code + ')'"></el-option>
        </el-select>

        <el-input style="width: 200px;margin-right: 3px" placeholder="ID" v-model="form.id" :disabled="loading"></el-input>
        <el-button type="primary" :disabled="loading" @click="init">查询</el-button>
    </div>
    <el-table :data="dataPage" v-loading="loading" style="margin-top: 20px" @sort-change="sortChange">
        <el-table-column label="ID" width="150" prop="image_id"></el-table-column>
        <el-table-column label="预览图" width="120">
            <template #default="scope">
                <el-image style="width: 100px" lazy :src="`${$CDN_URL}/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
<!--        <el-table-column label="激励" width="80" prop="level">-->
<!--            <template #default="scope">-->
<!--                {{ scope.row.cost ? '是' : '否' }}-->
<!--            </template>-->
<!--        </el-table-column>-->
        <el-table-column label="上线时间" width="140" prop="online_date" sortable></el-table-column>
        <el-table-column v-if="this.form.module==='rookie'" label="第几天" width="100" prop="day" sortable></el-table-column>
        <el-table-column label="展示" prop="impression" width="100" sortable></el-table-column>
        <el-table-column label="进入" prop="enter" width="100" sortable></el-table-column>
        <el-table-column label="完成" prop="finish" width="100" sortable></el-table-column>
        <el-table-column label="完成率" prop="finish_rate" width="100" sortable></el-table-column>
        <el-table-column label="退出" prop="exit" width="100" sortable></el-table-column>
        <el-table-column label="时长" prop="duration" width="100" sortable></el-table-column>
        <el-table-column label="完成时长" prop="finish_duration" width="120" sortable></el-table-column>
        <el-table-column label="提示道具" prop="hint" width="120" sortable></el-table-column>
        <el-table-column label="不同点数" prop="spot" width="120" sortable></el-table-column>
      <!--        <el-table-column label="分享" prop="share" width="100" sortable></el-table-column>-->
    </el-table>
    <div style="margin-top: 20px;text-align: center">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
</template>

<script>
import {timestampToDate} from "../../libs/utils";
import axios from "ts-axios-new";
import DateRangePicker from "../../base/DateRangePicker.vue";

export default {
    name: "Metric",
  components: {DateRangePicker},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], page: 1, size: 20, image_list: [],
            image_module_map: {"LIBRARY": '图库', "rookie": '新手图', "CHALLENGE" : '挑战关'},
            form: {
                id: '',
                start: timestampToDate(now - 24 * 3600 * 1000 - 10 * 3600 * 1000),
                end: timestampToDate(now - 24 * 3600 * 1000 - 10 * 3600 * 1000),
                country: '',
                // country: 'Brazil',
                module: "LIBRARY",
                isTranssion: true,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/metric`, {params: this.form}).then(res => {
                    this.data = res.data.data.metricList.data;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        sortChange({column, prop, order}) {
            if (prop) {
                this.data.sort(function (a, b) {
                    return order === 'descending' ? (b[prop] < a[prop] ? -1 : 1) : (a[prop] < b[prop] ? -1 : 1);
                })
            } else {
                this.data.sort(function (a, b) {
                    return b.enter_rate_3 - a.enter_rate_3;
                })
            }
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                // let valid = d.impression > 100;
                // valid &= !this.form.id || (d.image_id + '').includes(this.form.id);
                // return valid;
                return true;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    }
}
</script>

<style scoped>

.el-select {
  width: 200px;
}

</style>