<template>
    <div style="display: flex;flex-wrap: wrap;">
        <el-select v-model="query.series" clearable filterable placeholder="系列" @change="reload" style="width: 120px">
            <el-option v-for="series in this.series_list" :label="series" :value="series"></el-option>
        </el-select>
        <el-select v-model="query.status" placeholder="图片状态" clearable @change="reload" style="width: 100px">
            <el-option label="通过" value="AUDITED"></el-option>
            <el-option label="初审中" value="AUDITING"></el-option>
            <el-option label="审核中" value="REVIEWING"></el-option>
            <el-option label="不通过" value="REJECTED"></el-option>
            <el-option label="不使用" value="REFUSED"></el-option>
        </el-select>
<!--        <el-select v-model="query.unauthorized" placeholder="版权状态" clearable @change="reload" style="width: 100px">-->
<!--            <el-option label="无版权" :value="true"></el-option>-->
<!--            <el-option label="有版权" :value="false"></el-option>-->
<!--        </el-select>-->
        <el-select v-model="query.online" placeholder="上线状态" clearable @change="reload" style="width: 100px">
            <el-option label="已上线" :value="true"></el-option>
            <el-option label="未上线" :value="false"></el-option>
        </el-select>

        <el-select v-model="query.module" placeholder="模块" clearable @change="reload" style="width: 100px">
          <el-option v-for="(value, key) in image_module_enum" :label="value" :value="key"></el-option>
<!--          <el-option label="图库" value="LIBRARY"></el-option>-->
<!--            <el-option label="集合" value="COLLECTION"></el-option>-->
<!--            <el-option label="挑战关" value="CHALLENGE"></el-option>-->
<!--            <el-option label="每日挑战" value="DAILY"></el-option>-->
        </el-select>

        <el-select v-model="query.author" placeholder="作者" clearable @change="reload" style="width: 100px">
          <el-option label="王悦颜" value="王悦颜"></el-option>
          <el-option label="莫书玮" value="莫书玮"></el-option>
          <el-option label="刘文慧" value="刘文慧"></el-option>
          <el-option label="韦姿羽" value="韦姿羽"></el-option>
          <el-option label="孔繁灿" value="孔繁灿"></el-option>
          <el-option label="余青" value="余青"></el-option>
          <el-option label="邓子毅" value="邓子毅"></el-option>
          <el-option label="李奇峰" value="李奇峰"></el-option>
          <el-option label="汪倩倩" value="汪倩倩"></el-option>
        </el-select>
        <el-input v-model="query.id" placeholder="ID" @change="reload" style="width: 220px"></el-input>
        <date-range-picker v-model="query" end-placeholder="上线时间结束" start-placeholder="上线时间开始" style="width: 200px"
                           @change="reload" clearable :disabled-date="function() {}"></date-range-picker>
        <el-button :disabled="loading" type="primary" @click="openDialog()">上传</el-button>
    </div>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="data">
        <el-table-column label="ID" prop="id" width="100"></el-table-column>
        <el-table-column label="nickname" prop="nickname" width="100"></el-table-column>
        <el-table-column label="系列" prop="series" width="120">
          <template #default="scope">
            <el-tag :disabled="loading" v-if="scope.row.series" v-for="series in scope.row.series">{{ series }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="150">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 100px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="初审中" value="AUDITING"></el-option>
                    <el-option label="审核中" value="REVIEWING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                    <el-option label="不使用" value="REFUSED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="上传人" prop="author" width="100"/>
        <el-table-column label="上传时间" prop="create_date" width="130"/>
        <el-table-column label="新手图" width="100">
              <template #default="scope">
                  <el-tag v-if="scope.row.rookie">新手图</el-tag>
              </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="220">
            <template #default="scope">
              <el-image lazy :src="`${$CDN_URL}/${scope.row.origin}`" style="width: 200px"/>
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="200">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
<!--        <el-table-column label="版权状态" width="150">-->
<!--          <template #default="scope">-->
<!--            <el-tag v-if="scope.row.unauthorized" type="danger">无版权</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="模块" prop="module" width="100">
          <template #default="scope">
            {{ image_module_enum[scope.row.module]}}
<!--            {{ {LIBRARY: '图库', COLLECTION: '集合', DAILY: '每日', CHALLENGE: '挑战关'}[scope.row.module] }}-->
          </template>
        </el-table-column>

       <el-table-column label="操作" width="400">
            <template #default="scope">
                <el-button :disabled="loading" :type="getDiffButtonType(scope.row)" size="small" @click="$refs.differences.open(scope.row)">不同点</el-button>
                <el-button :disabled="loading" size="small" @click="openDialog(scope.row)">编辑</el-button>
                <el-button :disabled="loading" size="small" @click="downloadDiff(scope.row)">下载</el-button>
                <el-button :disabled="loading" size="small" type="danger" @click="remove(scope.row)">删除</el-button>
                <el-button v-if="has_feature_reprocess" :disabled="loading" size="small" type="danger" @click="reprocess(scope.row)">重新处理</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading"></el-pagination>
        <el-select v-model="query.limit" style="width: 100px" @change="reload" :disabled="loading">
            <el-option :value="20"></el-option>
            <el-option :value="50"></el-option>
            <el-option :value="100"></el-option>
        </el-select>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="100px" :model="form">
            <el-form-item label="原始图片" prop="origin" :rules="[{required: true, message: '请选择图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'images'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.origin" :src="`${$CDN_URL}/${form.origin}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="不同图片" prop="differences" :rules="[{required: true, message: '请选择图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleDiffSuccess"
                          accept="image/*" :data="{prefix: 'images'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.differences" :src="`${$CDN_URL}/${form.differences}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="模块" prop="module" :rules="[{required: true, message: '请选择模块'}]">
              <el-radio-group v-model="form.module" :disabled="loading">
                <el-radio v-for="(value, key) in image_module_enum" :label="key">{{value}}</el-radio>
<!--                <el-radio label="LIBRARY">图库</el-radio>-->
<!--                <el-radio label="COLLECTION">集合</el-radio>-->
<!--                <el-radio label="CHALLENGE">挑战关</el-radio>-->
<!--                <el-radio label="DAILY">Daily</el-radio>-->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="系列" prop="series" :rules="[{required: false, message: '请选择系列'}]">
                <el-select v-model="form.series" filterable multiple allow-create :disabled="loading">
                    <el-option v-for="s in series_list" :value="s"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上传人" prop="author" :rules="[{required: true, message: '请选择系列'}]">
              <el-select v-model="form.author" filterable allow-create :disabled="loading">
                <el-option v-for="s in ['莫书玮', '余青', '邓子毅', '李奇峰', '汪倩倩']" :value="s"></el-option>
              </el-select>
            </el-form-item>
<!--          <el-form-item label="版权" prop="unauthorized">-->
<!--              <el-checkbox v-model="form.unauthorized" :disabled="loading">无版权</el-checkbox>-->
<!--          </el-form-item>-->
<!--            <el-form-item label="包含国家" prop="include_country">-->
<!--                <el-select v-model="form.include_country" filterable multiple :disabled="loading" style="width: 100%">-->
<!--                    <el-option v-for="c in $root.country_list" :value="c.code"-->
<!--                               :label="c.en_name + ' (' + c.code + ')'"></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="排除国家" prop="exclude_country">-->
<!--                <el-select v-model="form.exclude_country" filterable multiple :disabled="loading" style="width: 100%">-->
<!--                    <el-option v-for="c in $root.country_list" :value="c.code"-->
<!--                               :label="c.en_name + ' (' + c.code + ')'"></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
  <Differences ref="differences"></Differences>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update} from "../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'
import {getUserName} from "../libs/user";
import Differences from "./Differences.vue";
import {has_feature_reprocess} from "../libs/permission";

export default {
    name: "Index",
    components: {Differences, DateRangePicker},
    data() {
        return {
            loading: false, data: [], total: 0, page: 1, dialog_opened: false, editing: null, series_list: [],
            image_module_enum: {"LIBRARY": '图库', "COLLECTION": '集合', "CHALLENGE": '挑战关', "DAILY": '每日挑战'},
            query: {
                  id: null,
                  online: null,
                  status: null,
                  series: null,
                  // module: null,
                  limit: 20,
                  skip: 0,
                  start: null,
                  end: null,
                  unauthorized: null
            },
            form: {
                origin: "",
                differences: "",
                // cost: 0,
                series: [],
                // source: null,
                module: 'LIBRARY',
                status: null,
                online_date: null,
                include_country: [],
                exclude_country: [],
                desc: null,
                unauthorized: null,
                author: null,
            },
        }
    },
    methods: {
      has_feature_reprocess,
        init() {
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            });
        },
        reload() {
            if (!this.query.id || this.query.id.length === 24) {
                this.skip = 0;
                this.page = 1;
                this.init();
            }
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        pageChange(page) {
            this.query.skip = (page - 1) * this.query.limit;
            this.page = page;
            this.init();
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/image/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
        downloadDiff(row) {
          const downloadLink = document.createElement('a');
          downloadLink.href = this.$CDN_URL + "/" + row.resource;
          downloadLink.setAttribute('download', '');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
                this.form.author = getUserName()
            })
        },
        reset() {
            if(this.$refs.form){
                this.$refs.form.resetFields();
            }
            this.loading = this.dialog_opened = this.dialog_diff_opened = false;
            this.editing = null;
            this.form.include_country = [];
            this.form.exclude_country = [];
            this.form.unauthorized = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/image/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.editing.series = res.data.data.series
                            this.editing.author = res.data.data.author
                            this.reset();
                        })
                    } else {
                        axios.post('/cms/v1/image', this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.origin = res.data.name
        },
        handleDiffSuccess(res) {
            this.loading = false;
            this.form.differences = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = _ => {
                        if (image.height === 1000 && image.width === 1600) {
                            // const allowedExtensions = ['.webp'];
                            // const ext = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                            // if (allowedExtensions.includes(`.${ext}`)) {
                            resolve();
                            // } else {
                            //     reject(new Error('Invalid file extension. Allowed extensions are: webp.'));
                            // }
                        } else {
                            reject();
                        }
                    }
                }
            }).then(_ => {
                return file;
            }, _ => {
                ElMessage.error('图片尺寸必须是1600 * 1000');
                return Promise.reject()
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/image/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        reprocess(row) {
          axios.put(`/cms/v1/image/${row.id}/reprocess`).then(_ => {
          })
        },
        getDiffButtonType(row) {
          if (!row.reviewed) {
            return 'danger';
          } else {
            return 'primary';
          }
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get('/cms/v1/series').then(res => {
                this.series_list = [];
                res.data.data.seriesList.forEach(s => {
                    if (s && s.key && !this.series_list.includes(s.key)) {
                        this.series_list.push(s.key);
                    }
                });
            }),
        ]).then(_ => {
          this.init();
        })
    },
}
</script>

<style scoped>

</style>