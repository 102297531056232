<template>
    <div id="app">
        <el-container>
            <el-header height="64px"
                       style="display: flex;padding: 0 20px;justify-content: space-between;border-bottom: solid 1px var(--el-border-color-light)">
                <div style="display: flex">
                    <div style="height: 64px">
                        <img style="margin: 16px 0;height: 32px;cursor: pointer;" @click="$router.push({name: 'Image'})"
                             src="https://lh3.googleusercontent.com/CzUhfer4E7PjdsGUoF8cQpHa4VOU71DGqs52U9D4nWriTNdYWCgouyD7o4yt3gYPAEI">
                    </div>
                    <div style="padding: 0 10px;line-height: 64px;font-size: 20px;color: var(--el-text-color-regular);">
                        Find Difference
                        <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                    </div>
                </div>
                <div>
                    <el-switch style="margin: 16px" v-model="dark" active-color="#444444" @change="changeDark"
                               active-icon="Moon" inactive-icon="Sunny" inline-prompt></el-switch>
                </div>
            </el-header>
            <el-container :style="{height: height + 'px'}" style="background-color: var(--el-border-color-extra-light)">
                <aside-nav ref="nav">
                    <aside-group title="素材库">
                      <aside-item index="Image" title="图库" icon="Files"></aside-item>
                    </aside-group>
<!--                    <aside-group title="外部素材库">-->
<!--                      <aside-item index="ImageJigsaw" title="Jigsaw" icon="Files"></aside-item>-->
<!--                    </aside-group>-->
                    <aside-group title="主线玩法">
                      <aside-item icon="Files" title="系列" index="Series"></aside-item>
                      <aside-item icon="Calendar" title="每日更新" index="Update"></aside-item>
                      <aside-item icon="Clock" title="关卡表现" index="Metric"></aside-item>
                    </aside-group>
                    <aside-group title="周边玩法">
                      <aside-item index="Daily" title="每日挑战" icon="Calendar"></aside-item>
                      <aside-item index="Challenge" title="挑战关" icon="Search"></aside-item>
                      <aside-item icon="Collection" title="合集" index="Collection"></aside-item>
                    </aside-group>
                    <aside-group title="内容运营">
<!--                      <aside-item index="CountryHot" title="国家热图" icon="HotWater"></aside-item>-->
<!--                      <aside-item index="CountryRookie" title="国家新手图" icon="Checked"></aside-item>-->
                      <aside-item index="CountryManual" title="手动新手图" icon="FolderAdd"></aside-item>
                    </aside-group>
                    <aside-group title="运营活动">
  <!--                    <aside-item icon="Orange" title="轮播图" index="Carousel"></aside-item>-->
  <!--                    <aside-item icon="HelpFilled" title="悬浮按钮" index="Suspend"></aside-item>-->
                      <aside-item icon="Monitor" title="弹窗" index="Popup"></aside-item>
                    </aside-group>
                    <aside-group title="内购">
<!--                        <aside-item index="IAP" title="内购页布局" icon="Document"></aside-item>-->
                        <aside-item index="Subscription" title="订阅活动" icon="Goods"></aside-item>
                        <aside-item index="NewbieSales" title="新用户活动" icon="Goods"></aside-item>
                    </aside-group>
                    <aside-group title="传音">
                      <aside-item icon="Files" title="图库" index="TranssionImage"></aside-item>
                      <aside-item icon="FolderAdd" title="新手图" index="TranssionRookie" ></aside-item>
<!--                      <aside-item icon="Calendar" title="每日更新" index="TranssionUpdate"></aside-item>-->
                      <aside-item icon="Clock" title="每日表现" index="TranssionPerformance"></aside-item>
                    </aside-group>
                </aside-nav>
                <el-main>
                    <el-scrollbar>
                        <router-view></router-view>
                    </el-scrollbar>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import {useDark, useToggle} from '@vueuse/core'
import AsideNav from "./base/AsideNav";
import AsideItem from "./base/AsideItem";
import AsideGroup from "./base/AsideGroup";
import axios from "ts-axios-new";

export default {
    name: 'App',
    components: {AsideGroup, AsideItem, AsideNav},
    data() {
        return {
            height: document.documentElement.clientHeight - 64,
            width: document.documentElement.clientWidth,
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
            country_list: [],
        }
    },
    methods: {
        init() {
        },
        changeDark() {
            useToggle(useDark())(this.dark);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 64;
            this.width = document.documentElement.clientWidth;
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.init();
        this.changeDark();
        const media = window.matchMedia('(prefers-color-scheme: dark)');
        media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        axios.get('https://console.plutodesk.com/api/v1/country').then(res => {
            this.country_list = res.data.data.countryList;
        });

    }
}
</script>
<style>
body {
    margin: 0;
    padding: 0;
}
</style>